<template>
  <div class="page-roleManage">
    <div class="page-top-nav">
      <a-tabs type="card" @change="switchPlatform" :defaultActiveKey="activePlatformCode">
        <a-tab-pane v-for="item in typesMap.type" :key="item.value" :tab="item.label"></a-tab-pane>
      </a-tabs>
    </div>
    <a-card :bordered="false" class="-mt-8">
      <div class="queryView">
        <div class="queryView-conditions">
          <a-form layout="inline" :form="form">
            <a-form-item label="角色代码">
              <a-input v-decorator="['roleCode']" placeholder="请输入角色代码"></a-input>
            </a-form-item>
            <a-form-item label="角色名称">
              <a-input v-decorator="['roleName']" placeholder="请输入角色名称"></a-input>
            </a-form-item>
            <a-form-item label="启用状态">
              <a-select class="form-control sm" v-decorator="['state']" placeholder="请选择" allowClear>
                <a-select-option v-for="(item, index) in typesMap.state" :key="index" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
              <div class="condition-btns">
                <a-button type="primary" @click="doQuery(1)">查询</a-button>
                <a-button type="default" @click="resetAndQuery">重置</a-button>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div class="queryView-actions">
          <a-button icon="plus-circle" @click="add">新增</a-button>
        </div>
        <div class="queryView-main">
          <a-table
            class="queryView-table"
            rowKey="id"
            bordered
            :loading="loading"
            :columns="tableColumns"
            :dataSource="dataSource"
            :expandIconColumnIndex="1"
            :pagination="false"
            size="middle"
          ></a-table>
          <base-pagination
            :totalCount.sync="totalCount"
            :pageNo.sync="pageNo"
            :pageSize.sync="pageSize"
            @change="pageChange"
          />
        </div>
      </div>
    </a-card>
    <!-- 添加/编辑弹框 -->
    <a-modal :title="modal.title" v-model="modal.visible" @ok="handleOk" :width="900">
      <a-form :form="modal.form" class="flex-form form-label-6">
        <a-form-item label="角色名称">
          <a-input
            v-decorator="[
              'roleName',
              {
                rules: [{ required: true, message: '请输入角色名称' }],
              },
            ]"
            :disabled="modal.data.built"
            placeholder="请输入角色名称"
          ></a-input>
        </a-form-item>
        <a-form-item label="角色代码">
          <a-input
            v-decorator="[
              'roleCode',
              {
                rules: [{ required: true, message: '请输入角色代码' }],
              },
            ]"
            :disabled="modal.data.built"
            placeholder="请输入角色代码"
          ></a-input>
        </a-form-item>

        <a-form-item label="角色类型">
          <a-select
            class="form-control sm"
            v-decorator="[
              'type',
              {
                rules: [{ required: true, message: '请选择角色类型' }],
              },
            ]"
            disabled
            :options="typesMap.type"
            placeholder="请选择"
          ></a-select>
        </a-form-item>
        <!-- <a-form-item label="状态">
          <a-switch
            :disabled="modal.data.built"
            v-decorator="[
              'state',
              {
                valuePropName: 'checked',
              },
            ]"
          ></a-switch>
        </a-form-item> -->
        <a-form-item label="菜单权限">
          <AuthList v-model="modal.data.checkList" :dataSource="modal.data.roleAuthList"></AuthList>
        </a-form-item>
        <!-- <a-form-item hidden>
          <a-input v-decorator="['roleId']"></a-input>
        </a-form-item> -->
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { queryViewMixin } from '@/utils/mixin'
import AuthList from './modules/AuthList'
export default {
  components: {
    AuthList,
  },
  mixins: [queryViewMixin],

  data() {
    const tableColumns = [
      {
        title: '角色代码',
        width: '25%',
        align: 'center',
        dataIndex: 'roleCode',
      },
      {
        title: '角色名称',
        width: '25%',
        align: 'center',
        dataIndex: 'roleName',
      },
      {
        title: '启用状态',
        width: '25%',
        align: 'center',
        dataIndex: 'state',
        customRender: (text, row, index) => {
          const checked = text
          // 内置角色不可停用删除
          const canDelOrDisable = !row.built
          return (
            <a-switch
              disabled={!canDelOrDisable}
              loading={row.switchLoading}
              size="small"
              checked={checked}
              onChange={() => {
                this.changeState(row, !checked, index)
              }}
            />
          )
        },
      },
      {
        title: '操作',
        width: '25%',
        align: 'center',
        dataIndex: 'detail',
        customRender: (text, row, index) => {
          return (
            <div>
              <span>
                <a
                  class="row-action-btn"
                  onClick={() => {
                    this.edit(row)
                  }}
                >
                  修改
                </a>
              </span>
            </div>
          )
        },
      },
    ]
    return {
      form: this.$form.createForm(this),
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      loading: false,
      tableColumns: tableColumns,
      dataSource: [],
      activePlatformCode: 'web',
      modal: {
        visible: false,
        form: this.$form.createForm(this),
        title: '',
        data: {
          checkList: [],
          roleAuthList: [],
        },
      },
    }
  },
  computed: {
    typesMap() {
      return {
        state: [
          { value: 1, label: '启用' },
          { value: 0, label: '停用' },
        ],
        type: [
          { value: 'web', label: '系统菜单' },
          // { value: 'app', label: 'APP' },
          { value: 'uni', label: '统一门户' },
        ],
      }
    },
    modalFormLayout() {
      return {
        labelCol: {
          span: 6,
        },
        wrapperCol: {
          span: 18,
        },
      }
    },
  },

  methods: {
    async doQuery(_pageNo = this.pageNo, _pageSize = this.pageSize) {
      this.loading = true
      try {
        const res = await this.$axios.post('/ums/role/listPage', {
          ...this.getPageParams(_pageNo, _pageSize),
          type: this.activePlatformCode === 'web' ? '' : 'uni',
        })
        const list = res.data.data
        const { pageSize, pageNo, totalCount } = res.data
        this.totalCount = totalCount
        this.pageSize = pageSize
        this.pageNo = pageNo
        this.dataSource = list
      } catch (e) {}
      this.loading = false
    },
    async getAuthList({ type = this.activePlatformCode, roleId = '' } = {}) {
      let roleAuthList = []
      let checkList = []
      if (type === 'web') {
        const res = await this.$axios.post(`/ums/role/queryAuthByRoleId`, {
          roleId,
          // type,
        })
        roleAuthList = res.data.roleAuthList ?? []
        checkList = res.data.checkList ?? []
      }
      if (type === 'uni') {
        const res = await this.$axios.post(`/unify-home/role/queryAuthByRoleId`, {
          roleId,
        })
        roleAuthList = res.data.roleAuthList ?? []
        checkList = res.data.checkList ?? []
      }
      return { roleAuthList, checkList }
    },
    async add() {
      const { roleAuthList, checkList } = await this.getAuthList()
      this.resetModal()
      this.modal.visible = true
      this.modal.title = '新增'
      this.modal.data = { roleAuthList, checkList }
      this.safeSetFieldsValue(this.modal.form, { type: this.activePlatformCode })
    },
    async edit(row) {
      const { roleAuthList, checkList } = await this.getAuthList({ roleId: row.roleId })
      this.resetModal()
      this.modal.visible = true
      this.modal.title = '修改'
      this.modal.data = { ...row, roleAuthList, checkList }
      this.safeSetFieldsValue(this.modal.form, row)
    },
    async changeState(row, state, index) {
      const bizId = 'roleId'
      this.$set(this.dataSource[index], 'switchLoading', true)
      try {
        await this.$axios.post('/ums/role/updateJson', {
          [bizId]: row[bizId],
          state,
        })
        this.doQuery()
        this.$message.success('操作成功!')
      } catch (e) {}
      this.$set(this.dataSource[index], 'switchLoading', false)
    },

    handleOk() {
      this.modal.form.validateFields(async (errors, values) => {
        if (!errors) {
          const params = values
          try {
            let roleId = this.modal.data.roleId
            if (this.modal.data.id) {
              await this.$axios.post('/ums/role/updateJson', {
                roleId: this.modal.data.roleId,
                ...params,
              })
            } else {
              const res = await this.$axios.post('/ums/role/saveJson', {
                roleId: this.modal.data.roleId,
                ...params,
              })
              roleId = res.data
            }
            if (this.activePlatformCode === 'web') {
              await this.$axios.post('/ums/role/updateJson', {
                roleId,
                checkList: this.modal.data.checkList,
              })
            }
            if (this.activePlatformCode === 'uni') {
              await this.$axios.post('/unify-home/role/saveJson', {
                roleId,
                authAddList: this.modal.data.checkList,
              })
            }
            this.$message.success('操作成功!')
            this.resetModal()
            this.doQuery()
          } catch (e) {
            console.error(e)
          }
        }
      })
    },
    /** 平台切换 */
    switchPlatform(code) {
      this.activePlatformCode = code
      this.form.resetFields()
      this.doQuery(1)
    },
  },
  mounted() {
    this.doQuery()
  },
}
</script>

<style lang="less" scoped>
.tabs {
  width: 100%;
  height: 38px;
  background: #eeeeee;
  border-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  display: flex;
  .btn {
    width: 100px;
    height: 100%;
    line-height: 38px;
    text-align: center;
    font-size: 14px;
    color: #333333;
    cursor: pointer;
  }
  .active {
    background: #ffffff;
  }
}
</style>
